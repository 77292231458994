<template>
  <div class="dynamic-content container-fluid px-0">
    <b-jumbotron class="text-white headBanner headerBG side_distance">
      <b-container class="row align-items-end max-w mx-auto">
        <div class="sellHead">
          <h1 class="headerH1 text-center text-lg-left">
            {{ $t("Sell.Header.SellCarsEasy") }}
          </h1>
          <div class="headerText text-center text-lg-left">
            {{ $t("Sell.Header.SetPricesInCurrency") }}
          </div>
          <div class="text-center text-lg-left">
            <b-button
              pill
              class="sellButton mr-2"
              variant="warning"
              :to="{ name: 'listingOption' }"
            >
              {{ $t("Sell.Header.SellMyCar") }}
            </b-button>

            <!-- <b-button pill class="sellButton" variant="outline-warning">
              {{ $t("Sell.Header.SeeTheAdvantages") }}
            </b-button> -->
          </div>
        </div>
      </b-container>
    </b-jumbotron>
  </div>
</template>

<script>
export default {
  name: "Sell",
  props: {
    text: {
      type: Array,
    },
  },
};
</script>

<style scoped lang="scss">
.headerBG {
  background: url("~@/assets/img/sell-header.png") #d9e9f6;
  background-size: 590px;
  background-repeat: no-repeat;
  background-position: 70% bottom;
  padding-top: 155px !important;
  @media (max-width: 1700px) {
    background-position: 85% bottom;
  }
  @media (max-width: 1199px) {
    background-position: 95% bottom;
    background-size: 530px;
  }
  @media (max-width: 991px) {
    background: #d9e9f6;
  }
  @media (max-width: 767px) {
    padding-top: 80px !important;
  }
}
.sellHead {
  padding-bottom: 47px;
}

.side_distance {
  padding: 100px 0 0 0;
  border-radius: 0;
  margin-bottom: 0;
}
.sellHead h1 {
  max-width: 600px;
  margin-top: 100px;
  margin-bottom: 24px;
  font-size: 48px;
  color: #0051a5;
  line-height: 68px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.headerText {
  max-width: 490px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: #363636;
  @media (max-width: 991px) {
    max-width: 100%;
  }
}

.max-w {
  max-width: 1300px;
}
.sellButton {
  padding: 0 16px;
  font-weight: 600;
  margin-top: 40px;
  background: #fedf01;
  height: 64px;
  min-width: 180px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #111111;
  font-size: 20px;
  border: none !important;
  margin-bottom: 48px;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #d8be01;
  }
}

@media (max-width: 576px) {
  .side_distance {
    padding: 0px;
    padding-top: 100px;
  }
  .sellHead h1 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 16px;
  }
  .headerText {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  }

  .sellButton {
    margin-top: 24px;
    padding: 12px 24px;
  }
}
</style>
